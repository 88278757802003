/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/dist/css/bootstrap.min.css';
/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }

.kpinumber {
  margin-top: 10px;margin-bottom: 20px
;font-size: 18px;font-weight: 100;
  color: cornflowerblue; font-weight:400;
}


body {

  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1.5;
  margin: 0;
  text-align: left;
  color: #3e4676;
  background-color: rgb(240,249,255);
}
* {
  box-sizing: border-box;
}
table{
  width: 100%;

}
.Table{
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  overflow: auto;
  overflow-x:auto;
}
.mat-row:nth-child(even){

  background-color:white;
  border-right:none;
  border-left:none;
  border-bottom:none;
  border-top:none
}
tr{
  color: cornflowerblue;
}
.mat-row:nth-child(odd){

  background-color:#f1f9ff;
  border-right:none;
  border-left:none;
  border-bottom:none;
  border-top:none
}
td{
  border-right:none;
  border-left:none;
  border-bottom:none;
  border-top:none
}

/* Style the search field */
form.form-default input[type=text] {
  padding: 10px;
  font-size: 20px;
  border: 1px solid rgb(194, 191, 191);
  float: left;
  width: 80%;
  background: #fcf9f9;
}

/* Style the submit button */
form.form-default button {
  float: left;
  width: 20%;
  padding: 10px;
  background: #2699fb;
  color: white;
  font-size: 20px;
  border: 1px solid rgb(194, 191, 191);
  border-left: none; /* Prevent double borders */
  cursor: pointer;
}



form.form-default button:hover {
  background: #0b7dda;
}

/* Clear floats */
form.form-default::after {
  content: "";
  clear: both;
  display: table;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(133, 154, 247);
  opacity: 1; /* Firefox */
}


.btn{
  widht:100% !important;
  padding: 10px;
  background: #2699fb !important;
  color: white;
  font-size: 20px;
  border: 1px solid rgb(194, 191, 191);
  border-left: none; /* Prevent double borders */
  cursor: pointer;
}
.responsive_bar {
  width: 100%;
  max-width: 295px;
  height: auto;
}
.responsive_pie {
  width: 100%;
  max-width: 132px;
  height: auto;
}

.logo {
  max-width: 132px;
  height: auto;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}


/* Add a Blue background color to the top navigation */
.topnav {
  background-color: #2699fb;
  overflow: hidden;
}

/* Style the links inside the navigation bar */
.topnav a {
  float: left;
  color:white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

/* Change the color of links on hover */
.topnav a:hover {
  background-color: rgb(81, 144, 216);
  color: black;
}

.topnav span {
  float: left;
  color:white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

/* Change the color of links on hover */
.topnav span:hover {
  background-color: rgb(81, 144, 216);
  color: black;
}


/* Right-aligned section inside the top navigation */
.topnav-right {
  float: right;
}
/* Style the search box inside the navigation bar */
.topnav input[type=text] {
  float: right;
  padding: 6px;
  border: none;
  margin-top: 8px;
  margin-right: 16px;
  font-size: 17px;
}

/* When the screen is less than 600px wide, stack the links and the search field vertically instead of horizontally */
// @media screen and (max-width: 600px) {
//   .topnav a, .topnav input[type=text] {
//     float: none;
//     display: block;
//     text-align: left;
//     width: 100%;
//     margin: 0;
//     padding: 14px;
//   }
//   .topnav input[type=text] {
//     border: 1px solid #ccc;
//   }
// }


